import React from 'react';
import * as f from "../common/Funcs";
import {Panel} from "@sencha/ext-react-modern";
import BasePage from "./BasePage";
import {ReloadButton, XlsButton} from "../common/Buttons";
import {BaseButton} from "./BaseButtons";
import ErrorBoundary from "../ErrorBoundary";

const Ext = window['Ext'];
const keyName = `BaseAccPage`;

export default class BaseAccPage extends BasePage {
    static defaultProps = {acc: 'acc'};

    constructor(props) {
        if (window.IasConfig.devMode) console.debug(`${keyName} constructor`, arguments);
        //очистка фильтра при загрузке, иначе путается с мониторингом и реестром
        super(props);
        const context = this;
        document.setCookie('accFilter', document.getCookie(context.props.acc + 'Filter'));
        window.p = context;
        context.filters = [{name: 'year',pos:1, required: true, message: f.locale('needyear')},
            {name: 'region',pos:2, required: false},
            {name: 'users',pos:3, required: false},
        ];
        context.gridName = "AccGrid";
        context.tableName = 'acc_table';
        context.idName = 'row_id';
        context.hiddenColumns = [context.idName];
        context.topMenuButtonNames = ['xls', 'html', 'reload','label'];
        context.footerButtonNames = ['save'];
        //меню страницы
    }

    //кнопка reload общая для всех страниц

    componentDidMount() {
        const context = this;
        const app = context.props.appViewPort;
        app.setState({filters: context.filters, rightWidth: context.rightWidth},
            () => {
                app.setState({filters: context.filters, rightWidth: context.rightWidth}, () => {
                    context.filters.map(k =>
                        app.filters[`${k.name.firstToUpper()}Filter`]?.setState({required: k.required}));
                });
                if (app.autoload) context.update(context);
            });
    }

    /*печатная форма*/
    getJson() {
        const context = this;
        const result = {};
        result.subs = [];
        result.subs.push(context.getColData({context}));
        result.subs[0].desc = {title: context.title, view_name: context.gridName};

        return result;

    }

    addReloadButton(i) {
        const context = this;
        const key = 'reloadbutton';
        context.topMenuButtons[i]=(<ReloadButton key={key} text={'Обновить'} func={() => {

            const reload = () => {
                if (context.getData) context.getData({context});
                if (context.grid) context.grid.getData({context: context.grid});
            }
            const modified = context.grid.grid.cmp.store.getData().items.filter(i => i.data.modified).map(i => i.data).length;
            const acc = document.getCookie('accFilter');
            if (acc && modified > 0) {
                const closeFunc = (result) => {
                    if (result.flash) {
                        f.alert({
                            title: 'Ответ сервера',
                            message: `Изменения не сохранены. Сервер вернул ошибку: ${JSON.stringify(result.flash)}`
                        });
                    } else {
                        f.toast({title: 'Ответ сервера', message: 'Изменения сохранены.', timeout: window.IasConfig.messageTimeout});
                        reload();
                    }
                }
                Ext.Msg.show({
                    title: 'Предупреждение',
                    message: 'Не все изменения были сохранены. Подтвердите, пожалуйста, действие.',
                    buttons: {
                        ok: {text: 'Обновить без сохранения'},
                        save: {text: 'Сохранить и обновить'},
                        close: {text: 'Отменить'}
                    },
                    fn: (e, value) => {
                        switch (e) {
                            case 'save':
                                const modified = context.grid.grid.cmp.store.getData().items.filter(i => i.data.modified).map(i => i.data);
                                f.setAccsData({accName: acc, values: modified, callback: closeFunc});
                                break;
                            case 'ok':
                                reload();
                        }
                    }
                });
            } else {
                reload();
            }
        }}/>)
    }

    //кнопка экспорта в xls
    addXlsButton(i) {
        const context = this;
        const key = (context.accId == '2') ? 'accs' : context.state.acc_form;
        if (!key) return '';
        const callFunc = () => {
            window.open(`${window.IasConfig.homePath}forms/${key}`);
            window.focus();
        };
        context.topMenuButtons[i]=(<XlsButton key={key} context={context} func={callFunc} text={'Экспорт в XLS'}/>)
    }

    //кнопки для меню
    addAdditButtons() {
        if (window.IasConfig.devMode) console.debug(`${keyName} addAdditButton`);
        const context = this;
        const key = 'savebutton';
        context.topMenuButtons[10]=(<BaseButton name={'saveButton'} key={'saveButton'} iconCls={'fa-save'} text={'Сохранить'}
                                                func={()=>context.saveFunc(context)}/>);
    }

    addLabelButton(i) {
        const context = this;
        if (window.IasConfig.devMode) console.debug(`${keyName} addRelationButton`);
        const key = 'label';
        const filters=context.props.parent.filters;
        const accName=filters?.StockFilter?.getTitle?.()||filters?.MonitoringFilter?.getTitle?.();
        const accSpecName=filters?.AccspecFilter?.getTitle?.();

        context.topMenuButtons.push(<Panel className={'acc-label'} title={((accName)?`Учет: ${accName}. `:'')+((accSpecName)?`Вид учета: ${accSpecName}.`:'')}/>)
        //context.setState({manuButtons:context.topMenuButtons});

    }
    saveFunc (context,callback)  {
        if (context.grid.grid.cmp) {
            const modified = context.grid.grid.cmp.store.getData().items.filter(i => i.data.modified).map(i => i.data);
            debugger;
            const acc = document.getCookie('accFilter');
            if (acc && modified.length > 0) {
                const closeFunc = (result) => {
                    if (result.flash) {
                        f.alert({
                            title: 'Ответ сервера',
                            message: `Изменения не сохранены. Сервер вернул ошибку: ${JSON.stringify(result.flash)}`
                        });
                    } else {
                        context.grid.setState({modified:false});
                        if (callback) callback(result);
                        f.toast({
                            title: 'Ответ сервера',
                            message: 'Изменения сохранены.',
                            timeout: 1000
                        });
                    }
                }
                f.setAccsData({accName: acc, values: modified, callback: closeFunc})
            }
        }
    }

    render() {
        const context = this;
        //тело таблицы
        const grid = React.createElement(context.getGridType(context).el, {
            parent: context,
            accId: context.accId,
            appViewPort: context.props.appViewPort,
            key: context.gridName,
            name: context.gridName,
            height: '100%',
            context: context,
            ref: (grid) => context.grid = grid
        });
        const editMenu = context.getEditMenu(context);
        const p = context.getEditType(context).el;
        return (<ErrorBoundary key={`error${keyName}`}><Panel
            ref={p => (p) ? (context.cmp = p.cmp) : context.cmp = null}
            height={f.getCentralHeight()}
            region={"center"} margin="3"
            key={`gridPanel${keyName}`}
        >
            {editMenu}
            {grid}
        </Panel></ErrorBoundary>)
    }
}

